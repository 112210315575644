export const PRIVACY = {
  user: {
    title: '用户服务协议',
    content: '用户服务协议'
  },
  private: {
    title: '隐私协议',
    content: '隐私协议'
  }
};
