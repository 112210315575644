<template>
  <div class="pa-16">
    <Navbar title="协议" />
    <div class="font-16 fw-500 mb-24">{{ title }}</div>
    <div>{{ content }}</div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { PRIVACY } from './constants/index';
import { useRouterQuery } from '@hook/useRouterQuery';

export default defineComponent({
  name: 'privacy',
  setup() {
    const query = useRouterQuery();
    return {
      title: PRIVACY[query.privacy].title,
      content: PRIVACY[query.privacy].content
    };
  }
});
</script>
